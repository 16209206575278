<template>
	<div
		style="border-top: 8px solid var(--v-border-base) !important"
	>
		<v-app-bar
			height="142px"
			color="background"
		>
			<v-row
				align="center"
				justify="center"
				no-gutters
			>
				<v-col
					class="pl-12 d-flex justify-start applicationHeader"
					sm="4"
				>
					<span class="text-center">
						<b>G+B</b> Digital <b>Presenter</b>
					</span>
				</v-col>
				<v-col
					class="d-flex justify-center align-center"
					sm="4"
				>
					<img
						:src="require(`@/assets/logos/G+B/logo (light text).png`)"
						class="mx-2 my-2 d-none d-sm-block"
						height="80"
						@click="navigateFixed($store.state.presenter.structure.type, [])"
					/>
				</v-col>
				<v-col
					class="pr-12 d-flex justify-end"
					sm="4"
				>
					<GbButton
						color="tertiary"
					>
						<!-- substitute v-select for v-overflow-btn for different design -->
						<v-select
							class="customizedSelect"
							v-model="$i18n.locale"
							:items="$store.state.localization.languages"
							item-text="label"
							item-value="code"
							color="white"
							preprend-icon="mdi-language"
							hide-details
							dark
							reverse
							:menu-props="{ offsetY: true, offsetX: false }"
						>
							<template
								#selection={item}
							>
								<v-list-item-title
									class="d-flex"
								>
									<span
										:class="`fi fi-${item.icon ?? item.code} ml-1 mr-2`"
									/>
									{{ item.label }}
								</v-list-item-title>
							</template>
							<template
								#item={item}
							>
								<v-list-item-title
									class="d-flex"
								>
									{{ item.label }}
									<v-spacer/>
									<span
										:class="`fi fi-${item.icon ?? item.code}`"
									/>
								</v-list-item-title>
							</template>
						</v-select>
					</GbButton>
					<GbButton
						class="ml-2"
						color="tertiary"
					>
						<v-img
							src="icons/G+B/email_white.png"
							height="1rem"
							width="1rem"
							class="mr-2"
						/>
						{{ $t('app.contact') }}
					</GbButton>
				</v-col>
			</v-row>
		</v-app-bar>
	</div>
</template>

<script>
import GbButton from '@/components/GbButton.vue';

import NavigationHelper from '@/libraries/navigationhelper.js';

export default
{
	components:
	{
		GbButton
	},
	
	methods:
	{
		...NavigationHelper
	},
	
	computed:
	{
		
	},
}
</script>

<style lang="scss" scoped>
.applicationHeader
{
	font-size: 2rem;
	color: var(--v-light-base);
	text-transform: uppercase;
	letter-spacing: .5rem;
}

.customizedSelect
{
	width: min-content;
	
	.v-input__slot:before, .v-input__slot:after
	{
		content: unset !important;
	}
	
	.v-input__append-inner
	{
		padding-left: 0px;
		margin-left: -4px;
	}
}
</style>