<template>
	<v-app>
		<div
			class="customBackground darkThemed"
		/>
		<!-- <div
			v-show="$route.name === 'Home'"
			class="customBackground lightThemed"
		>
			<div class="layer">
				<div class="rectangle"/>
				<div class="triangle"/>
			</div>
			<div class="layer">
				<div class="rectangle"/>
				<div class="triangle"/>
			</div>
		</div> -->
		<div
			v-show="navigationVisible"
			class="appBarContainer"
		>
			<v-app-bar
				height="142px"
				color="background"
				flat
			>
				<v-row
					no-gutters
				>
					<v-col
						:class="`applicationHeader d-flex align-center ${$vuetify.breakpoint.smAndDown ? 'justify-center' : 'pl-12 justify-start'}`"
						md="4"
						sm="8"
					>
						<div class="text-center">
							<b>G+B</b> Digital <b>Presenter</b>
						</div>
					</v-col>
					<v-col
						v-if="!$vuetify.breakpoint.smAndDown"
						class="d-flex justify-center"
						md="4"
					>
						<img
							:src="require(`@/assets/logos/G+B/logo (light text).png`)"
							class="mx-2 my-2 d-none d-sm-block"
							height="80"
							@click="navigateFixed($store.state.presenter.structure.type, [])"
						/>
					</v-col>
					<v-col
						:class="`applicationButtons d-flex ${$vuetify.breakpoint.smAndDown ? 'flex-column justify-space-around align-center' : 'pr-12 justify-end align-center'}`"
						md="4"
					>
						<GbButton
							class="my-2 mx-2"
							color="tertiary"
							style="max-width: 148px"
						>
							<!-- substitute v-select for v-overflow-btn for different design -->
							<v-select
								class="customizedSelect"
								v-model="$i18n.locale"
								:items="$store.state.localization.languages"
								item-text="label"
								item-value="code"
								color="white"
								item-color="tertiary"
								preprend-icon="mdi-language"
								hide-details
								dark
								reverse
								:menu-props="{ offsetY: true, offsetX: false, dark: true }"
							>
								<template
									#selection={item}
								>
									<v-list-item-title
										class="d-flex"
									>
										<span
											:class="`fi fi-${item.icon ?? item.code} ml-1 mr-2`"
										/>
										{{ item.label }}
									</v-list-item-title>
								</template>
								<template
									#item={item}
								>
									<v-list-item-title
										class="d-flex"
									>
										{{ item.label }}
										<v-spacer class="mx-2"/>
										<span
											:class="`fi fi-${item.icon ?? item.code}`"
										/>
									</v-list-item-title>
								</template>
							</v-select>
						</GbButton>
						<GbButton
							class="my-2 mx-2"
							color="tertiary"
							@click="contactVisible = true"
						>
							<v-img
								src="icons/G+B/email_white.png"
								height="1rem"
								width="1rem"
								class="mr-2"
							/>
							{{ $t('app.contact') }}
						</GbButton>
					</v-col>
				</v-row>
			</v-app-bar>
		</div>
		
		<div class="topLeftPositioner">
			<v-btn
				v-show="backButtonVisible && $vuetify.breakpoint.mdAndUp"
				class="ma-4"
				color="tertiary"
				fab
				icon
				x-large
				@click="navigateHighOrBack()"
			>
				<v-icon
					x-large
				>
					mdi-arrow-left
				</v-icon>
			</v-btn>
		</div>
		
		<!-- <div
			v-if="navigationVisible"
		>
			<v-toolbar
				class="d-flex align-center"
				flat
			>
				<v-btn
					v-show="backButtonVisible"
					class="ml-2"
					color="secondary"
					icon
					large
					outlined
					elevation="2"
					@click="navigateHighOrBack()"
				>
					<v-icon>
						mdi-arrow-left
					</v-icon>
				</v-btn>
				<Breadcrumbs
					:itemNames="['name', 'title']"
				/>
			</v-toolbar>
		</div> -->
		
		<v-main
			:style="`height: ${navigationVisible ? 'calc(100vh - 128px - 64px)' : '100vh'}`"
		>
			<router-view
				class="router-view"
			/>
		</v-main>
		
		<v-navigation-drawer
			v-model="contactVisible"
			style="background-color: var(--v-background-base);"
			right
			fixed
			temporary
			dark
			height="100%"
		>
			<v-list>
				<v-list-item>
					<v-avatar
						class="my-4 mr-4"
						size="64"
					>
						<img
							src="images/Angelika Penger.jpg"
						/>
					</v-avatar>
					<v-list-item-content>
						<v-card-title>
							Angelika<br>
							Penger
						</v-card-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider/>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="tertiary">mdi-phone</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						02204 204 255
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="tertiary">mdi-email</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<a color="tertiary" href="mailto:a.penger@gb-mediensysteme.de">a.penger@gb-mediensysteme.de</a>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		
		<!-- <Notification
			:visible="notification.visible"
			:text="notification.text"
			:template="notification.template"
			:color="notification.color"
			:icon="notification.icon"
		/> -->
	</v-app>
</template>

<script>
import '/node_modules/flag-icons/css/flag-icons.min.css';

import GbButton from '@/components/GbButton.vue';

import AppBar from '@/components/AppBar.vue';
import Notification from '@/components/Notification.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import VueRouterUtility from '@/libraries/vue_router_utility.js';
import NavigationHelper from '@/libraries/navigationhelper.js';
import Cart from '@/libraries/cart.js';

export default
{
	name: 'App',
	
	components:
	{
		GbButton,
		AppBar,
		Notification,
		Breadcrumbs
	},
	
	beforeCreate ()
	{
		this.$store.state.presenter.environment = this.$wrapper.type();
	},
	
	async created ()
	{
		// we will read timeout from setup.ini
		if (this.$wrapper.type() === 'Presenter')
		{
			this.resetTime = await this.$wrapper.screenSaverTimeout();
		}
		
		if (this.resetTime)
		{
			document.addEventListener('mousemove', () =>
			{
				this.reinitializeResetTimeout();
			});
			
			document.addEventListener('keypress', () =>
			{
				this.reinitializeResetTimeout();
			});
			
			document.addEventListener('click', () =>
			{
				this.reinitializeResetTimeout();
			});
			
			window.addEventListener('reinitializeResetTimeout', () =>
			{
				console.log('received reinitialize timeout event');
				
				this.reinitializeResetTimeout();
			});
			
			this.reinitializeResetTimeout();
		}
	},
	
	methods:
	{
		...NavigationHelper,
		
		reinitializeResetTimeout ()
		{
			// disable screensaver via settings
			if (!this.resetTime)
			{
				return;
			}
			
			if (this.resetTimeout)
			{
				clearTimeout(this.resetTimeout);
			}
			
			this.resetTimeout = setTimeout(
				() =>
				{
					if (VueRouterUtility.routesEqual(this.$router.resolve('/screensaver').resolved, this.$router.currentRoute))
					{
						return;
					}
					
					console.log('reset');
					
					Cart.clear();
					
					NavigationHelper.navigateFixed('/screensaver');
				},
				this.resetTime * 1000
			);
		}
	},
	
	computed:
	{
		notification:
		{
			get ()
			{
				return this.$store.getters['notification/get'];
			},
			set ()
			{
				this.$store.commit('notification/set', arguments);
			}
		},
		
		indexesUrlParameterEmpty ()
		{
			// need this so that the computed property updates when the route query 'indexes' updates
			this.$route.query?.indexes;
			
			return NavigationHelper.getParsedIndexesQuery().length === 0;
		},
		
		navigationVisible ()
		{
			return !['Screensaver'].includes(this.$route?.name);
		},
		
		backButtonVisible ()
		{
			if (['Screensaver', 'Home'].includes(this.$route?.name))
			{
				return false;
			}
			
			// need this so that the computed property updates when the route query 'indexes' updates
			this.$route.query?.indexes;
			
			return window.history.length > 0 || NavigationHelper.getParsedIndexesQuery().length === 0;
		},
		
		objectInCart ()
		{
			return this.$route.path === '/pdf' && this.$store.state.cart.items.has(this.$store.state.presenter.object?.id);
		}
	},
	
	data: () =>
	(
		{
			resetTime: 0,
			resetTimeout: null,
			contactVisible: false
		}
	)
};
</script>

<style lang="scss">
@font-face
{
	font-family: 'Ubuntu';
	src: url('fonts/Ubuntu/Ubuntu-R.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

*
{
	font-family: 'Ubuntu', Helvetica, sans-serif;
}

// hide scrollbar if not needed
html
{
	overflow-y: auto;
}

.router-view
{
	min-height: 100%;
	//height: 100%;
}

// vuetify overrides
.v-toolbar .v-toolbar__content
{
	padding: 4px;
	align-items: center;
	
	.v-toolbar__title
	{
		align-self: auto !important;
		padding-bottom: 0px !important;
		font-size: 2rem;
		user-select: none;
	}
}

.v-carousel
{
	.v-window__prev, .v-window__next, .v-carousel__controls
	{
		z-index: 0;
	}
}

.theme--dark.v-list
{
	background-color: var(--v-background-base);
}

#app
{
	background: transparent;
}

.topLeftPositioner
{
	position: absolute;
	top: 150px;
	left: 0;
	z-index: 99;
}

.applicationHeader
{
	font-size: 2rem;
	color: var(--v-light-base);
	text-transform: uppercase;
	letter-spacing: .5rem;
}

.customizedSelect
{
	width: min-content;
	
	.v-input__slot:before, .v-input__slot:after
	{
		content: unset !important;
	}
	
	.v-input__append-inner
	{
		padding-left: 0px !important;
		margin-left: -8px !important;
	}
}

.appBarContainer
{
	box-sizing: border-box;
	//border-top: 8px solid var(--v-border-base) !important;
	border-top: 4px solid var(--v-border-base) !important;
	border-bottom: 4px solid var(--v-border-base) !important;
}

.customBackground
{
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	z-index: -1;
	
	&.darkThemed
	{
		background-color: var(--v-background-base);
	}
	
	&.lightThemed
	{
		background-color: var(--v-light-base);
		
		.triangle
		{
			width: 100%;
			height: 200px;
			clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
		}
		
		.layer:nth-child(1)
		{
			.rectangle
			{
				height: 300px;
			}
			
			.rectangle, .triangle
			{
				background: var(--v-background-base);
			}
		}
		
		.layer:nth-child(2)
		{
			position: relative;
			top: -250px;
			left: 0px;
			z-index: -1;
			
			.rectangle
			{
				height: 100px;
			}
			
			.triangle
			{
				height: 250px;
			}
			
			.rectangle, .triangle
			{
				background: var(--v-secondary-base);
			}
		}
	}
}
</style>